<template>
    <div>
        <Header />
        <Search />
        <div class="ding">
            <div class="dingdan">
                <div class="ding_L">
                    <div class="wenzi">
                        <div class="wenzi_T">
                            <div class="tijiao">订单提交成功，请尽快付款！</div>
                            <div class="danhao">
                                流水号：{{ istrue == '1' ? orderId : mergeOrderNo }}
                            </div>
                        </div>
                        <div class="wenzi_B">
                            请您在尽快<span class="red"></span>完成支付，否则订单会被自动取消
                        </div>
                    </div>
                </div>
                <div class="ding_R">
                    <div class="yingfu">
                        应付金额：<span class="price">￥{{ sumPrice }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="company-info-wrapper">
            <div class="company-info">
                <div class="top">
                    <div class="top_l">余额支付</div>
                    <div class="top-r" @click="fangshi">
                        其他方式支付<i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div v-for="(item, index) in info" :key="index">
                    <div class="list" v-if="item.orderStatus == '0'">
                        <table cellpadding="0" cellspacing="0">
                            <tr>
                                <td>店铺名称</td>
                                <td>{{ item.storeName }}</td>
                            </tr>
                            <tr>
                                <td>当前余额</td>
                                <td>￥{{ Number(item.storeBalance).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>应付金额</td>
                                <td>￥{{ Number(item.orderAmount).toFixed(2) }}</td>
                            </tr>
                        </table>

                        <el-button class="button" type="primary" @click="toPay(item.orderId,Number(item.storeBalance).toFixed(2),Number(item.orderAmount).toFixed(2))">支付</el-button>
                    </div>
                </div>
                <div class="warn-info">
                    <h4>注意事项（转账必读）</h4>
                    <p>
                        1、下单后请尽快转账，务必保证转账金额与订单金额一致，请勿多转、少转和分次转账，否则影响订单对账进度；
                    </p>
                    <p>2、汇款时将汇款识别码填写至汇款单“备注/摘要”等栏；</p>
                </div>
            </div>
        </div>
        <!-- <Baoyou/> -->
        <div class="kongbai"></div>
        <Foot />
    </div>
</template>
<script>
    import Header from '@/components/header.vue';
    import { get, post } from '@/utils/request';
    import Foot from '@/components/foot.vue';
    import Search from '@/components/order/buy_succeed/search.vue';
    import Baoyou from '@/components/order/baoyou.vue';
    export default {
        components: {
            Header,
            Foot,
            Search,
            Baoyou,
        },
        data() {
            return {
                mergeOrderNo: null,
                sumPrice: 0,
                sign: '',
                info: [],
                orderId: '',
                istrue: '2',
                mergeOrderNo: '',
                itemId: ''
            };
        },
        created() {
            this.istrue = this.$route.query.istrue;
            this.orderId = this.$route.query.orderId;
            this.sumPrice = this.$route.params.price;
            this.getPayDetail(this.$route.query.orderId);
        },
        watch: {
            info() {
                if (this.info.findIndex((target) => target.orderStatus == '0') == -1) {
                    this.$router.push({
                        name: 'Buy_succeed',
                    });
                }
            },
        },
        methods: {
            /**
             * 切换其他支付方式
             */
            fangshi() {
                this.$router.push({
                    name: 'Zhuanzhang',
                    params: {
                        price: this.sumPrice,
                    },
                    query: {
                        orderId: this.orderId,
                        istrue: this.istrue,
                    },
                });
            },

            /**
             * 获取订单信息
             * @param e
             */
            getPayDetail(e) {
                if (this.istrue == '1') {
                    this.getStoreBalance();
                } else {
                    get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
                        this.mergeOrderNo = res.data.data.orderNo;
                        this.getStoreBalance();
                    });
                }
            },
            /**
             * 获取店铺余额
             */
            getStoreBalance() {
                if (this.istrue == '1') {
                    get(
                        'api/orderbasic/getOrderByMergeOrderNo?mergeOrderNo=' +
                        this.orderId +
                        '&isTrue=1'
                    ).then((res) => {
                        this.info = res.data.data;
                    });
                } else {
                    get(
                        'api/orderbasic/getOrderByMergeOrderNo?mergeOrderNo=' +
                        this.mergeOrderNo +
                        '&isTrue=2'
                    ).then((res) => {
                        this.info = res.data.data;
                    });
                }
            },
            /**
             * 去支付
             * @param orderId 订单ID
             * @param storeBalance 店铺剩余金额
             * @param orderAmount 应支付金额
             */
            toPay(orderId,storeBalance,orderAmount) {
                // 验证余额是否足够支付
                if (Number(storeBalance) < Number(orderAmount)) {
                    this.$message.error({
                        message: '余额不足,请充值后支付',
                    });
                } else {
                    // 执行余额支付
                    post('api/orderbasic/updatePayBalance', {
                        orderId: orderId,
                        paymentAmount: orderAmount
                    }).then((res) => {
                        if (res.data.code == 0) {
                            this.getPayDetail(this.orderId);
                        } else {
                            this.$message.error({
                                message: res.data.msg,
                            });
                        }
                    })
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
    .list {
        border-bottom: 1px solid #666;
        padding-bottom: 20px;
    }
    table {
        margin-top: 40px;
    }
    .button {
        margin-top: 20px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        line-height: 78px;
        text-align: center;
    }
    .avatar {
        width: 400px;
        height: 200px;
    }
    .company-info-wrapper {
        background-color: #f5f5f5;
    }

    .company-info {
        width: 900px;
        padding: 20px 40px;
        margin: 0 auto;
        background-color: #fff;
        .top {
            display: flex;
            justify-content: space-between;
            .top_l {
                font-size: 20px;
            }
            .top-r {
                cursor: pointer;
            }
        }
    }

    .company-info h4 {
        margin: 10px 0 30px 0;
        color: #666;
        font-size: 16px;
        font-weight: normal;
    }

    .company-info h4 span {
        color: #1950ff;
        font-weight: bold;
    }

    .company-info table {
        font-size: 16px;
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
        width: 100%;
    }

    .company-info table tr td:first-child {
        font-weight: bold;
    }

    .company-info table tr:first-child {
        background-color: #f6f6f6;
    }

    .company-info table td {
        padding: 10px 20px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .warn-info h4 {
        font-weight: bold;
        font-size: 14px;
        color: #000;
        margin: 20px 0 10px 0;
    }
    .warn-info p {
        font-size: 14px;
        color: #333;
        margin: 0 0 10px 0;
    }
</style>

<style lang="scss" scoped>
    .ding {
        width: 100%;
        background: #f5f5f5;

        .dingdan {
            width: 1240px;
            height: 123px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ding_L {
                display: flex;

                .erweima {
                    width: 95px;
                    height: 90px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .wenzi {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 20px;

                    .wenzi_T {
                        font-size: 16px;
                        display: flex;

                        .tijiao {
                            color: #3e3e3e;
                        }

                        .danhao {
                            color: #282828;
                        }
                    }

                    .wenzi_B {
                        font-size: 14px;
                        margin-top: 15px;
                        color: #3f3f3f;

                        .red {
                            color: #e41412;
                        }
                    }
                }
            }

            .ding_R {
                display: flex;
                flex-direction: column;

                .yingfu {
                    color: #333;
                    font-size: 16px;

                    .price {
                        font-size: 16px;
                        color: #e53739;
                    }
                }

                .detail {
                    font-size: 14px;
                    color: #1b50fe;
                    margin-top: 15px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
</style>
